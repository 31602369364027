import React from 'react'
import { useState ,useRef} from 'react'
import Logo1 from '../../../Assets/authentication.svg'
import Logo2 from '../../../Assets/otp.svg'
import Logo3 from '../../../Assets/login2fa.svg'
import Logo from '../../../Assets/qrcode.svg'
import { Link } from 'react-router-dom'
import Recoverykey from './TwofactorModals/recoverykey'

function TwoFactor() {
  const [isEnable, setIsEnable] = useState(false)
  const [enable,setEnable] = useState(false)

  const handleCheckboxChange = () => {
    setEnable(!enable);
  };

  const handleEnable2fa = () => {
    setIsEnable(!isEnable);
  }
  
  const [isSuccess, setIsSuccess] = useState(false);   
  const handleIsSuccess = () => {
    setIsSuccess(!isSuccess)
  }
    // handle input of verification code
    const inputRefs = useRef([]);

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            const nextIndex = index + 1;
            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }else if (e.key === 'Backspace') {
            const prevIndex = index - 1;
            if (prevIndex >= 0) {
                e.target.value = "";
                inputRefs.current[prevIndex].focus();
                inputRefs.current[prevIndex].value = ''; // Remove the entered number
            }
        }
    };

  // Handle inpot change and Start from 1st BOX
    const handleInputChange = (e, index) => {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
      }
  };

  // Handle Keydown and delete input values
  const addInputRef = (ref, index) => {
      if (ref && !inputRefs.current.includes(ref)) {
          inputRefs.current.push(ref);
          if (index === inputRefs.current.length - 1) {
              ref.onkeydown = (e) => handleKeyDown(e, index);
          }
      }
  };

  return (
    <section className='bg-white rounded-md px-4 overflow-x-auto w-full h-max'>
      {isEnable ? (
        <>
        {isSuccess ?(
          //<TwoFactorSuccess/>
          <Recoverykey/>
        ):(
          <div className="relative w-full h-max ">
          <h2 className="text-[22px] font-semibold mb-2 text-gray-700">Enable Two-Factor</h2>
          <hr className="py-2 border-gray-200"/>
  
          {/* QR code and option */}
          <div className="flex w-full h-full ">
            <div className="flex flex-col items-start w-full">
              <p className="font-medium  mb-4 text-gray-600">
                You will need an authenticator mobile app to complete<br/> this process, such as one of the following:
              </p>
              <div className="flex flex-col items-start space-y-1">
                <Link to='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target="_blank" className="text-green-500">Google Authenticator</Link>
                <Link to='https://play.google.com/store/apps/details?id=com.authy.authy' target="_blank" className="text-green-500 ">Authy</Link>
              </div>
            </div>
            <div className="flex">
              <img className="w-48 h-48" src={Logo} alt="" />
            </div>
          </div>
  
          {/* Secret Code */}
          <div className="flex flex-col items-start w-full -mt-10">
              <p className="font-medium text-gray-600">
                Scan the QR code with your authenticator app:
              </p>
              <p className=" text-gray-400 py-3 text-center">If you can't scan the code, you can still enter below key into your authenticator app</p>
              <div className=" flex rounded-md justify-between px-3 py-2 w-full border border-gray-300">
                  <p className="font-medium text-gray-700">{`12fasd1214xfwueb2z`}</p>
                  <svg className="w-5 h-5 text-primary-700" aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 20' style={{ cursor: 'pointer' }}>
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"d="m7.708 2.292.706-.706A2 2 0 0 1 9.828 1h6.239A.97.97 0 0 1 17 2v12a.97.97 0 0 1-.933 1H15M6 5v4a1 1 0 0 1-1 1H1m11-4v12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 5h5.239A.97.97 0 0 1 12 6Z"/>
                  </svg>
              </div> 
          </div>
  
          {/* OTP Code */}
          <div className="flex flex-col items-start w-full mb-2 mt-6">
              <p className="font-medium text-gray-600">
                After scanning the QR code above, enter the six digit code generated by your authenticator app.
              </p>
              <div className="flex flex-row pt-4 ">
                  {/*OTP input Box*/}
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 0)} id="input1" onClick={handleCheckboxChange}
                              onChange={(e) => handleInputChange(e, 0)} className="w-12 h-12 text-center border outline-none rounded-md shadow-default focus:border-primary-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="number" maxLength="1" pattern="[0-9]" required autoFocus/>
                    </div>
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 1)} id="input2" onClick={handleCheckboxChange}
                              onChange={(e) => handleInputChange(e, 1)} className="w-12 h-12 text-center border outline-none rounded-md shadow-default focus:border-primary-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="number" maxLength="1" pattern="[0-9]" required/>
                    </div>
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 2)} id="input3" onClick={handleCheckboxChange}
                              onChange={(e) => handleInputChange(e, 2)} className="w-12 h-12 text-center border outline-none rounded-md shadow-default focus:border-primary-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="number" maxLength="1" pattern="[0-9]" required/>
                    </div>
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 3)} id="input4" onClick={handleCheckboxChange}
                              onChange={(e) => handleInputChange(e, 3)} className="w-12 h-12 text-center border outline-none rounded-md shadow-default focus:border-primary-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="number" maxLength="1" pattern="[0-9]" required/>
                    </div>
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 4)} id="input5" onClick={handleCheckboxChange}
                              onChange={(e) => handleInputChange(e, 4)} className="w-12 h-12 text-center border outline-none rounded-md shadow-default focus:border-primary-500[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="number" maxLength="1" pattern="[0-9]" required/>
                    </div>
                    <div className="w-16 h-16">
                      <input ref={(ref) => addInputRef(ref, 5)}  id="input6" onClick={handleCheckboxChange}
                            onChange={(e) => handleInputChange(e, 5)} className="w-12 h-12 outline-none text-center border rounded-md shadow-default focus:border-primary-500[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" type="text" maxLength="1" pattern="[0-9]" required/>
                    </div>
                </div>
          </div>
          <hr className="py-1 border-gray-200"/>
           
           {/* Buttons */}
          <div className="flex w-full space-x-3 mt-2 justify-end items-center">
              <button onClick={handleEnable2fa} className="flex bg-transparent border border-gray-300 hover:bg-red-600 hover:text-white hover:border-red-600 shadow-sm outline-none py-2 px-12 rounded-md font-semibold">
                Cancel
              </button>
              <button disabled={!enable} onClick={handleIsSuccess} className={`${enable ? 'bg-primary-500 hover:bg-primarys-600 shadow-sm outline-none py-2 px-8 text-white rounded-md font-semibold': 'bg-primary-400 hover:bg-primarys-600 shadow-sm outline-none py-2 px-8 text-white rounded-md font-semibold cursor-not-allowed'}`} >
                Next
              </button>
          </div>   
  
          </div>)}
        </>
      ):(
        <div className="">
            <p className='text-gray-400 text-xl font-semibold mt-4'>Two-factor authentication adds an extra layer of security to your account by asking for verification code when you sign in.</p>
            <div className='flex grid-col-3 space-x-10 mx-4 my-2'> 
                <div className='w-[350px]'>
                  <img  className='w-[250px] h-[250px]' src={Logo1} alt=''/>
                  <p className='font-semibold text-gray-500'>Log in using your email and password as usual.</p>
                </div>
                <div className='w-[350px]'>
                  <img  className='w-[250px] h-[250px]' src={Logo2} alt=''/>
                  <p  className='font-semibold text-gray-500'>Using two-factor authentication app, generate passcode to verify your identity.</p>
                </div>
                <div className='w-[350px]'>
                  <img  className='w-[250px] h-[250px]' src={Logo3} alt=''/>
                  <p  className='font-semibold text-gray-500'>Once you enter the passcode at the prompt, you'll be logged onto out DNS Watchtower</p>
                </div>
          </div>

          {/* Footer Buttons */}
          <div className="flex pb-4 mt-10 space-x-3 justify-end items-center">
              <button className="flex bg-red-500 hover:bg-red-600 shadow-[0_3px_10px_rgb(0,0,0,0.2)] outline-none py-2 px-12 text-white rounded-md font-semibold">
                Disable
              </button>
              <button onClick={handleEnable2fa} className='bg-primary-500 hover:bg-primary-600 shadow-[0_3px_10px_rgb(0,0,0,0.2)] outline-none py-2 px-12 text-white rounded-md font-semibold'>
                Enable
              </button>
          </div>  
        </div>
        )}
    </section>
  )
}

export default TwoFactor
