import Lottie from 'lottie-react';
import animationData from '../../../Assets/success.json';

export default function SuccessPage({handleisRecoverpassword}) {
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div className="fixed inset-0 bg-gray-400 bg-opacity-50 dark:bg-gray-500 dark:bg-opacity-75 shadow-xl transition-opacity"></div>
                <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg px-28 bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-max dark:bg-gray-800 dark:border-gray-700 dark:border">
                          <div className="p-2 md:mx-auto">
                            <Lottie
                                animationData={animationData}
                                loop={false}
                                autoplay={true}
                                className='w-56 h-56 mx-auto'
                              />
                            <div className="text-center">
                                <h3 className="md:text-2xl text-base -mt-3 text-gray-900 font-semibold text-center dark:text-white">Password Changed!</h3>
                                <p className="text-gray-600 my-2 dark:text-gray-200">Your password has been changed<br/>successfully.</p>
                                <div onClick={handleisRecoverpassword} className="inline-flex w-full my-6 justify-center cursor-pointer rounded-md bg-blue-700 text-sm text-white font-semibold py-2.5 px-10 shadow-sm hover:bg-primary-600 sm:ml-3 sm:w-auto">Done</div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
  )
}
