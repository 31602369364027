import { useState} from 'react'
import Loading from '../../Common/loading/loading';
import ServerError from '../../Common/ServerError/serverdown';
import axios from '../../API/axios';
import { useNavigate } from 'react-router-dom';

export default function Logout({actionPopup}) {

const [isLoading, setIsLoading] = useState(false);
const [serverError, setServerError] = useState(false);


const navigate = useNavigate();

// Handle Logout
    const handlelogout = () => {
        setIsLoading(true);
        axios.post("/api/v1/logout",
        {msg: 'yes sure'},
        {withCredentials: true}
        ).then(function(response){
            if (response.data.message === 'you have logged out'){
                navigate('/');
            }
            setIsLoading(false);
        }).catch(function(error){
            if (error.response){
                if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                    navigate("/login");
                } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                    navigate("/login");
                } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                    navigate("/login");
                } else{
                    setServerError(true);
                }
              } else{
                setServerError(true);
              }
              setIsLoading(false);
        });
    }

  return (
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-slate-300 bg-opacity-80">
        {isLoading && (<Loading/>)}
        {serverError ?(
            <ServerError/>
            ):(
            <div className="max-h-full w-full max-w-xl overflow-y-auto p-6 rounded-lg shadow-2xl bg-white">
                    <div className="flex">
                        <div>
                            <svg className="w-7 h-7 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                        </div>
                        <div className="ml-3">
                            <p className="mt-1 text-gray-600 leading-relaxed">Logging out of ShadowFinder - Are you sure?</p>
                        </div>
                    </div>
                    <div className="flex justify-end items-center mt-5">
                        <button onClick={actionPopup} className="px-6 py-2 bg-transparent border border-gray-300 text-gray-800 text-sm font-medium rounded-md">
                            Cancel
                        </button>
                        <button onClick={handlelogout} className="px-4 py-2 ml-2 bg-red-500 hover:bg-red-600 text-white text-sm font-medium rounded-md">
                                Continue
                        </button>
                    </div>            
                </div>
                )}
      </div>
       
  )
}
