import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import Logo from "../../../Assets/mainiconb.ico";
import axios from "../../API/axios";
import ServerError from '../../Common/ServerError/serverdown';
import { AppTitle } from "../../Common/Apptitle/Title";
import Loading from '../../../Components/Common/loading/loading';
import ErrorToast from "../../Common/Toast/Errortoast";
import SuccessToast from "../../Common/Toast/Successtoast";
import ReCAPTCHA from "react-google-recaptcha";

export default function Forgot() {
  const [email, setEmail] = useState("");
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const reCaptchaRef = useRef(null);

//  Title
 AppTitle("Forgot | ShadowFinder")
 const navigate = useNavigate();
  
// Handle Checkbox 
const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

// Toast Success
const showSuccessToast = (message) => {
 setToastMessage(message);
   setIsSuccessToast(true);
   setTimeout(() => {
      setIsSuccessToast(false);
    }, 5000);
};

  // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleRecaptcha(); // Trigger the search button click event
    }
  };

  const handleRecaptcha = (e) =>{
    e.preventDefault();
     if (email.length === 0 ) {
      showErrorToast("Please Provide Email !");
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter a valid email !");
    } else {
      reCaptchaRef.current.execute();
    }   
  }

const onChange = (token) => {
    axios.post('/api/v1/verifycaptcha', {
        token: token,
      },{ withCredentials: true })
       .then(function (response){ 
        reCaptchaRef.current.reset();
        if ( response.data.message === "user is registered successfully verify your email address"){
          showErrorToast("Your registration is complete. Please verify your email using the link sent to you.")
        }
        else if (response.data.message === "reCaptcha is verified" && response.status === 200){
        handleForgot();
      }
    })
    .catch(function (error){
      reCaptchaRef.current.reset();
      setServerError(true);
    });
};

//Captcha Site Key  
const RECAPTCHA_SITE_KEY = "6LeUCfMpAAAAAOdS-EkrbRnBiiJxGyiuUxzUJrA1";

  // Handle Sign in
  const handleForgot = () => {
      setIsLoading(true);
      axios.post("/api/v1/forgot",{
        email: email
      },{withCredentials: true}).then(function(response){
        showSuccessToast("A link to reset your password has been sent to your email address.");
        setEmail("");
        setIsLoading(false);
      }).catch(function(error){
        if (error.response) {
          if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
            showErrorToast("Too many request try after 1 hour");         
          } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
            setIsErrorToast('Token expired!');
          } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
            setIsErrorToast('Token expired!');
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
            setIsErrorToast('Token expired!');
          } else {
            setServerError(true);
          }
          setIsLoading(false);
        }
      });
  };

  return (
    <section className="bg-gray-50 bg-no-repeat bg-cover bg-center w-screen h-screen overflow-x-auto p-8">    
    {isLoading && (<Loading/>)}
     {serverError ?(
      <ServerError/>
      ):(
        <>
      <div className="flex flex-col items-center  justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* Shadow Finder Logo */}
      <Link href="#" className="flex items-center mb-6 text-3xl font-semibold text-black">
          <img className="w-10 h-10 mr-2" src={Logo} alt="logo"/>
          Shadow Finder   
      </Link>
        <form className="w-full p-6 bg-white shadow-2xl rounded-lg md:mt-0 sm:max-w-md  sm:p-8">
          <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Forgot your password?
          </h1>
              <p className="font-light text-[15px] text-gray-700">
              If you have forgotten your password, please enter your email address below.<br/> A password
              reset link will be sent to your inbox. Use this link to create a new password for your account.
            </p>
          <div className="mt-4 space-y-5">
            <div className="relative">
              <div className="inline-flex items-center justify-center absolute top-[14px] h-full w-10 text-gray-400">
                <svg
                  className="w-5 h-5 text-gray-800"
                  aria-hidden="true"
                  xmlns="F"
                  fill="none"
                  viewBox="0 0 20 16">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
              </div>
              <label
                className="block mb-2  text-sm font-medium text-gray-900" htmlFor="email">
                Your email
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                placeholder="name@company.com"
                required=""
                id="email"
                type="text"
                autoComplete="off"
                autoFocus
              />
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                {/* Check Box */}
                <input
                  id="checkbox"
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  onKeyPress={handleKeyPress} 
                  checked={isCheckboxChecked}
                  aria-describedby="terms"
                  className="w-4 h-4 border cursor-pointer border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                  required=""
                />
              </div>
              <div className="ml-3 text-sm">
                <p className="font-light text-gray-700">
                  I accept the{" "}
                  <Link
                    className="font-medium text-primary-600 hover:underline"
                    href="#">
                    Terms and Conditions
                  </Link>
                </p>
              </div>
            </div>

            {/* Recaptcha */}
            <ReCAPTCHA
                 sitekey={RECAPTCHA_SITE_KEY}
                 onChange={onChange}
                 size="invisible"
                 ref={reCaptchaRef}
            />

            {/* Reset Password Button */}
            <button
              id="sendemailButton"
              type="button" 
              onClick={handleRecaptcha}
              className={`w-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none font-medium rounded-md text-sm px-5 py-2.5 text-centery${
                !isCheckboxChecked && "opacity-50 cursor-not-allowed"
              }`}
              disabled={!isCheckboxChecked}>
              Send Email
            </button>{" "}
          </div>
        </form>
        <div className="mt-10 text-sm text-slate-100">
          © 2024 <Link to='https://shreshtait.com' target="_blank">Shreshta IT Technologies Pvt, Ltd. </Link> All rights reserved.
        </div>
      </div>
        {/* Toast */}
        {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
        )}
        {isSuccessToast && (
          <SuccessToast toastMessage={toastMessage}/>
        )}
      </>)}
    </section>
  );
}
