import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/navbar';
import Search from './search';
import axios from '../API/axios';
import ErrorToast from '../Common/Toast/Errortoast';
import animationData from '../../Assets/loading2.json';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import ServerError from '../Common/ServerError/serverdown';
import { AppTitle } from '../Common/Apptitle/Title';
// import { Link } from 'react-router-dom';

export default function Dashboard() {
    const [isErrorToast, setIsErrorToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [domain, setDomain] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const navigate = useNavigate();

    const domain_regex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;

    // App Title
    AppTitle('Home | ShadowFinder');
      

    useEffect(() => {
        if (isErrorToast) {
            const timer = setTimeout(() => {
                setIsErrorToast(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isErrorToast]);

    const showErrorToast = (message) => {
        setToastMessage(message);
        setIsErrorToast(true);
        console.log('Toast message set:', message);
        console.log('Is Error Toast set:', true);
    };

    const startRedirectTimer = () => {
        setIsProcessing(true);
        setCountdown(10);
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    navigate("/analytics");
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleSearch = () => {
        if (domain.length === 0) {
            showErrorToast('Please enter a domain name!');
        } else if (!domain_regex.test(domain)) {
            showErrorToast('Please enter a valid domain name!');
        } else {
            axios.post('api/v1/search', { domain: domain }, { withCredentials: true })
                .then(function(response) {
                    console.log('Server response:', response); // Debugging line to log the entire response
                    if (response.data.message === 'Domain name is in top 1 million') {
                        showErrorToast('You are not allowed to enter domain names from the top 1 million');
                    } else if (response.data.message === 'You have exceeded your limit') {
                        showErrorToast('You have exceeded your limit');
                    } else {
                        startRedirectTimer();
                    }
                })
                .catch(function(error) {
                    console.error('Error:', error); // Debugging line to log the entire error
                    if (error.response) {
                        console.log('Error response:', error.response); // Debugging line to log the error response
                        if (error.response.data.message === 'You have exceeded your limit') {
                            showErrorToast('You have exceeded your limit');
                        } else if (error.response.data.message && error.response.status !== 400) {
                            showErrorToast(error.response.data.message);
                            startRedirectTimer();
                        } else if (error.response.data.message && error.response.status === 400) {
                            showErrorToast(error.response.data.message);
                            startRedirectTimer();
                        } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                            navigate('/login');
                        } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token') {
                            navigate('/login');
                        } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired') {
                            navigate('/login');
                        } else {
                            setServerError(true);
                            startRedirectTimer();
                        }
                    } else {
                        setServerError(true);
                        startRedirectTimer();
                    }
                });
        }
    };
    
    return (
        <section className='w-screen h-screen overflow-hidden bg-slate-50'>
            {serverError ? (
                <ServerError />
            ) : (
                <>
                    {isProcessing ? (
                        <div className='flex justify-center items-center h-full'>
                        <div className='shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] rounded-lg p-12 bg-white'>
                            <h2 className='font-semibold text-gray-500 text-2xl text-center'>ShadowFinder is Scanning</h2>
                            <Lottie
                                animationData={animationData}
                                loop={true}
                                autoplay={true}
                                className='w-60 h-60 mx-auto'
                            />
                            <p className='text-gray-500 font-semibold dark:text-gray-200'>
                                ShadowFinder is currently scanning for subdomains under “{domain}”. <br /> This process may take a few minutes. Once the scan is complete, you will see the results on the analytics page. <br />
                                <br /> Thank you for your patience!
                            </p><br />
                            <p className='text-gray-500 font-semibold dark:text-gray-200'>
                                Redirecting in {countdown} seconds...
                            </p>
                            {/* <Link to='/home' className='text-blue-600 underline'>Click here <p className="text-gray-500 font-semibold dark:text-gray-200">to return to the home page</p></Link> */}
                        </div>
                    </div>
                    ) : (
                        <>
                            <Navbar />
                            <Search domain={domain} setDomain={setDomain} handleSearch={handleSearch} />
                            {isErrorToast && <ErrorToast toastMessage={toastMessage} />}
                        </>
                    )}
                </>
            )}
        </section>
    );
}
