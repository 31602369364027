import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const Piechart = ({asn,count, PiechartHeight}) => {
  const donutChartRef = useRef(null);

  useEffect(() => {
  
    // Donut Chart Options
    const donutChartOptions = {
      series: count,
      chart: {
        height: PiechartHeight, 
        width: "100%",
        type: "donut",
      },
      zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
        },
        selection: {
            background: '#90CAF9',
            border: '#0D47A1'
        }    
    },
      stroke: { colors: ["transparent"], lineCap: "" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Total Count ",
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return `${sum}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
            size: "70%",
          },
        },
      },
        onItemClick: {
          toggleDataSeries: true
      },
      
      grid: { padding: { top: -2 } },
      labels: asn,
      dataLabels: { enabled: false },
      legend: { position: "right", fontFamily: "Inter, sans-serif" },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value ;
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value + "k";
          },
        },
        axisTicks: { show: false },
        axisBorder: { show: false },
      },
    };

    if (
      document.getElementById("donut-chart") &&
      typeof ApexCharts !== "undefined"
    ) {
      

      if (!donutChartRef.current) {
        const donutChart = new ApexCharts(
          document.getElementById("donut-chart"),
          donutChartOptions
        );
        donutChart.render();
        donutChartRef.current = donutChart;
      }
    }

    return () => {

      if (donutChartRef.current) {
        donutChartRef.current.destroy();
        donutChartRef.current = null;
      }
    };
  }, [asn, count, PiechartHeight]);

  return (
    // Pie Chart
        <div className="h- bg-white rounded-md shadow-default">
          <h5 className="text-lg font-bold text-gray-900 py-2.5 pl-5">
            Count By ASN
          </h5>
         
            <hr className="border-slate-300" />
            {asn.length > 0 ? (
            <div id="donut-chart" className="py-4"></div>
          ) : (
            <p className="text-gray-800 text-center py-40">
            No ASN Data
          </p>
          )}
        </div>
  );
};

export default Piechart;
