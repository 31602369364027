import icon from '../../Assets/mainiconb.ico';
import  {Link} from 'react-router-dom';
import { AppTitle } from '../Common/Apptitle/Title';

export default function LandingPage() {

 // App Title
  AppTitle("ShadowFinder")

  return (
    <section className='w-screen h-screen overflow-x-auto bg-gradient-to-br bg-white'>
        <nav className="bg-transparent fixed w-full z-20 top-0 start-0">
        <div className="flex flex-wrap items-center justify-between mx-auto p-2.5 px-4">
        <div className="items-center flex">
            <img src={icon} className="h-12 w-12 rounded-full" alt=""/>
            <p className='pl-4 text-2xl font-bold text-black font-inter'>ShadowFinder</p>
        </div>
        <div className='flex items-center justify-end pr-8'>
        <ul className="flex font-medium rtl:space-x-reverse flex-row gap-5">
            <li>
                <Link to="/home" className="block py-2 text-black text-lg font-inter rounded hover:text-primary-200">Login</Link>
            </li>
            <li>
                <Link to="/register" className="block py-2 text-lg text-black font-inter rounded hover:text-primary-200">Register</Link>
            </li>
            </ul>
        </div>
        </div>
        </nav>
        <div className='flex flex-col items-center space-y-10 pb-10'>
            <div className='pt-32 space-y-8'>
                <h1 class="mb-8 text-4xl font-extrabold text-center leading-snug font-inter text-black">
                    Domain shadowing attack detection<br/> and DNS zone monitoring tool
                </h1>
                <p className='text-black font-inter text-xl text-center'>Threat actors gain control of the DNS control panel of legitimate domain names<br/> by brute force and stealthily insert subdomains pointing to the attackers’ network infrastructure.</p>
            </div>
            <Link to="/register" className='bg-white rounded-full border py-2.5 px-10 font-semibold shadow-md'>
                Get started
            </Link>
            <div className='grid grid-cols-2 space-x-8 justify-items-center'>
            <div className="space-y-2 border inline-flex flex-col items-center rounded-lg shadow-2xl px-6 py-10">
                    <svg width="80px" height="80px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier"> <path d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                    </svg>
                    <h1 className='text-black font-inter text-2xl font-bold'>Detection</h1>
                    <p className='text-black font-inter font-medium text-center'>Provide a domain name and ShadowFinder<br/> will detect possible domain shadowing attacks</p>
                </div>
                <div className="space-y-2 border inline-flex flex-col items-center rounded-lg shadow-2xl px-6 py-10">
                    <svg width="80px" height="80px" fill="#000000" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="invisible_box" data-name="invisible box">
                            <rect width="48" height="48" fill="none"/>
                            </g>
                            <g id="Q3_icons" data-name="Q3 icons">
                            <path d="M29,42a1.9,1.9,0,0,1-1.9-1.4L20.9,21.9l-5,12.8A1.9,1.9,0,0,1,14.1,36a2.1,2.1,0,0,1-1.9-1.1L8.6,27.6,5.5,31.3a2,2,0,0,1-2.8.2,2,2,0,0,1-.2-2.8l5-6A1.9,1.9,0,0,1,9.2,22a2.1,2.1,0,0,1,1.6,1.1l3,6,5.3-13.8a2,2,0,0,1,3.8.1L28.8,33,36.1,5.5A1.9,1.9,0,0,1,37.9,4a2,2,0,0,1,2,1.4l6,18a2,2,0,0,1-3.8,1.2L38.2,13,30.9,40.5A1.9,1.9,0,0,1,29.1,42Z"/>
                            </g>
                        </g>
                    </svg>
                    <h1 className='text-black font-inter text-2xl font-bold'>Monitoring</h1>
                    <p className='text-black font-inter font-medium'>Monitor the DNS records of a domain name</p>
                </div>
            </div>
        </div>
    </section>

  )
}
