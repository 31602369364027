import React, { useState, useRef } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/mainiconb.ico";
import axios from "../../../Components/API/axios";
import ServerError from '../../Common/ServerError/serverdown';
import { AppTitle } from "../../../Components/Common/Apptitle/Title";
import Loading from '../../../Components/Common/loading/loading';
import ErrorToast from "../../Common/Toast/Errortoast";
import SuccessToast from "../../Common/Toast/Successtoast";
import ReCAPTCHA from "react-google-recaptcha";


export default function Register() {
  const [email, setEmail] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [PasswordIcon, setPasswordIcon] = useState(<IoEyeOffOutline />);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(<IoEyeOffOutline />);
  const [PasswordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
  const [isLoading, setIsLoading] = useState(false);
  const reCaptchaRef = useRef(null);

  // App Title
  AppTitle("Register| ShadowFinder")
 
 // Server Down-Error
  const [serverError, setServerError] = useState(false);

 // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

  // Toast Success
  const showSuccessToast = (message) => {
    setToastMessage(message);
    setIsSuccessToast(true);

    setTimeout(() => {
      setIsSuccessToast(false);
    }, 5000);
  };


  

 //   Enter button press
 const handleKeyDownOnInput = (e) => {
    if (e.key === 'Enter') {
        // Trigger click event on the "Sign in" button
        handleRecaptcha(e);
      }
    };

    const handleRecaptcha = (e) =>{
        e.preventDefault();
         if (email.length === 0 ) {
          showErrorToast("Please Provide Email !");
        } else if (!emailRegex.test(email)) {
          showErrorToast("Please Enter a valid email !");
        } else if (Password.length === 0 || confirmPassword.length === 0) {
          showErrorToast("Please Provide Passwords!");
        } else if (Password !== confirmPassword) {
          showErrorToast("Passwords Do Not Match!");
        } else if (!passwordRegex.test(Password)) {
          showErrorToast(<>Password must contain one lowercase letter, one uppercase letter, one digit,<br /> one special character, and be at least 8 character log</>);
        } else {
          reCaptchaRef.current.execute();
        }   
      }

  const onChange = (token) => {
        axios.post('/api/v1/verifycaptcha', {
            token: token,
          },{ withCredentials: true })
          .then(function (response){ 
             if ( response.data.message === "user is registered successfully verify your email address"){
              showErrorToast("Your registration is complete. Please verify your email using the link sent to you.")
            }  else if (response.data.message === "reCaptcha is verified" && response.status === 200){
              handleSignup();
            }   
        })
        .catch(function (error){
          reCaptchaRef.current.reset();
          setServerError(true);
        });
  };

 //Captcha Site Key  
  const RECAPTCHA_SITE_KEY = "6LeUCfMpAAAAAOdS-EkrbRnBiiJxGyiuUxzUJrA1";
    
    // Handle Signup and API
    const handleSignup = () => {
          setIsLoading(true);
          axios.post('/api/v1/register',
          {email: email,
          password: Password},
          {withCredentials: true})
          .then(function(response){
            showSuccessToast("User successfully registered. Please verify your email address.")
            setEmail("");
            setPassword('');
            setConfirmPassword('');
            setIsLoading(false);
         }).catch(function(error){
          if (error.response) {
            if(error.response.data.message === "email already used"){
              showErrorToast("Email already exists")
            } else if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
              showErrorToast("Too many request try after 1 hour");
            } else if (error.response.data.message && error.response.status === 400){
              showErrorToast(error.response.data.message)
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
              handleSignup();
            } else {
              setServerError(true);
            }
          }
            setIsLoading(false);
          })
      }
  

// Handle New Password
  const handlePassword = () => {
    if (PasswordType === "password") {
      setPasswordType("text");
      setPasswordIcon(<IoEyeOutline />);
    } else {
      setPasswordType("password");
      setPasswordIcon(<IoEyeOffOutline />);
    }
  };

  // Handle Confirm Password
  const handleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIcon(<IoEyeOutline />);
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIcon(<IoEyeOffOutline />);
    }
  };

  return (
    <> 
    {isLoading && (<Loading/>)}
    {serverError ?(
      <ServerError/>
      ):(
    <section className="bg-gray-50 bg-no-repeat bg-cover bg-center w-screen h-screen overflow-x-auto p-8">    
            <div className="flex flex-col mt-16 items-center justify-center">
              <Link href="#" className="flex items-center mb-6 text-3xl font-semibold text-black">
                    <img className="w-14 h-14 mr-2 rounded-full" src={Logo} alt="logo"/>
                    ShadowFinder  
              </Link>
                <div className="w-full p-6 bg-white rounded-lg shadow-default md:mt-0 sm:max-w-md sm:p-8">
                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        Create an account
                    </h2>
                  <form className="mt-4 space-y-4" onKeyDown={handleKeyDownOnInput} onSubmit={handleRecaptcha}>

                      {/* Email */}
                      <div className="relative">
                        <div className="inline-flex items-center justify-center absolute top-[14px] h-full w-10 text-gray-400">
                            <svg className="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="F"  fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"/>
                            </svg>
                        </div>
                        <label
                            className="block mb-2  text-sm font-medium text-gray-900" htmlFor="email">
                            Your email
                        </label>
                        <input onChange={(e) => setEmail(e.target.value)} className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"  placeholder="name@company.com" required="" id="email" type="text" autoComplete="off" autoFocus
                        />
                      </div>

                    {/* Password */}
                    <div className="flex flex-col mb-6">
                        <label  className="block mb-2 text-sm font-medium text-gray-900" required>
                        Password
                        </label>
                        <div className="relative">                                                                 
                        <span className="absolute inset-y-0 left-0 flex items-center px-2">
                            <button
                            onClick={handlePassword}
                            type="button"
                            className="rounded-lg p-2 focus:outline-none focus:shadow-outline text-gray-700"
                            >
                            {PasswordIcon}
                            </button>
                        </span>                                                                                                                                                       
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type={PasswordType}
                            value={Password}
                            placeholder="New Password"
                            className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                            required="" autoComplete="off"
                        />
                        </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="flex flex-col mb-6">
                        <label
                        className="block mb-2 text-sm font-medium text-gray-900" required>
                        Confirm Password
                        </label>
                        <div className="relative">                                                                 
                        <span className="absolute inset-y-0 left-0 flex items-center px-2">
                            <button
                            onClick={handleConfirmPassword}
                            type="button"
                            className="rounded-lg p-2 focus:outline-none focus:shadow-outline text-gray-700"
                            >
                            {confirmPasswordIcon}
                            </button>
                        </span>                                                                                                                                                       
                        <input
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={confirmPasswordType}
                            value={confirmPassword}
                            placeholder="Confirm password"
                            className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                            required="" autoComplete="off"
                        />
                        </div>
                    </div>

                    {/* Recaptcha */}
                    <ReCAPTCHA className="mt-2 flex justify-center"
                            sitekey={RECAPTCHA_SITE_KEY}
                            size="invisible"
                            ref={reCaptchaRef}
                            onChange={onChange} />
                        
                        <button
                        type="submit"
                        className="w-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none font-medium rounded-md text-sm px-5 py-2.5 text-center  cursor-pointer"
                        >
                        Sign Up
                        </button>

                        <div className="text-sm font-medium text-gray-500">
                         Already have an account? <Link to="/login" className="text-indigo-600 hover:underline">Sign in</Link>
                        </div>
                  </form>
              </div>
              <div className="mt-5 text-sm text-slate-100">
                  © 2024 <Link to='https://shreshtait.com' target="_blank">Shreshta IT Technologies Pvt, Ltd. </Link> All rights reserved.
                </div>
            </div>
            {/* Toast */}
       {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
       )}
        {isSuccessToast && (
          <SuccessToast toastMessage={toastMessage}/>
       )}
    </section>
    )}
    </>
  )
}

