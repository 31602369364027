import icon from '../../Assets/mainiconb.ico';
import  {Link, useNavigate} from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from '../API/axios';
import Logout from '../Auth/Logout/logout';

export default function Navbar() {
    const [isProfile, setIsProfile] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate()
    const [islogout, setIsLogout] = useState(false);
    const [analytics, setAnalytics] = useState(false);

  
    const handleProfileDropDown = () => {
      setIsProfile(!isProfile);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClickOutside = (event) => {
      if (
        isProfile &&
        event.target.closest('#dropdown') === null &  event.target.closest('.relative') === null) 
        {
        // Close the profile dropdown
        setIsProfile(false);
        }
  
      if (
        isDropdownOpen && event.target.closest('.relative') === null)
         {
        // Close the profile dropdown
        setIsDropdownOpen(false);
        }
    };
    useEffect(()=>{
      const currentURL = window.location.pathname;
      axios.get("/api/v1/profile",{withCredentials: true})
      .then(function(response){
          setEmail(response.data.email);
          if (response.data.credit_count > 0 && currentURL !== "/analytics") {
            setAnalytics(true);
          }
      }).catch(function(error){
        if (error.response){
          if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          }
        }
      })
  },[navigate])


  const handlelogout = () => {
    setIsLogout(!islogout);
  }

  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },[handleClickOutside]);

  return (
<nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
  <div className="flex flex-wrap items-center justify-between mx-auto p-2.5 px-4">
    <div className="items-center flex">
    <img src={icon} className="h-12 w-12 border  rounded-full" alt=""/>
      <ul className="flex flex-col font-medium border border-gray-100 rounded-lg bg-gray-50 ml-2 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
        <li>
          <Link to="/home" className="block py-2 px-3 text-gray-900 hover:text-primary-500  font-inter rounded" aria-current="page">Home</Link>
        </li>
        {analytics && (
          <li>
          <Link to="/analytics" className="block py-2 px-3 text-gray-900 hover:text-primary-500 font-inter rounded" aria-current="page">Analytics</Link>
        </li>
        )}
        {/* <li>
          <Link to="https://shreshtait.com/shreshta-about/" className="block py-2 px-3 text-gray-900 hover:text-primary-500">About</Link>
        </li>
        <li>
          <Link to="/contact" className="block py-2 px-3 text-gray-900 rounded hover:text-primary-500">Contact</Link>
        </li> */}
      </ul>
    </div>
      <button type="button" onClick={handleProfileDropDown}className="inline-flex text-sm relative bg-gray-800 rounded-full ring-4 ring-gray-300" id="user-menu-button"aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span className="w-7 h-7 rounded-full text-white inline-flex items-center justify-center font-semibold text-xl uppercase">{email[0]}</span>
      </button>
      {/* Profile Dropdown */}
      {isProfile && (
                    <div className="absolute z-10 my-4 w-56 top-10 right-1.5 overflow-hidden text-base list-none bg-white rounded divide-y divide-gray-100 shadow-default dark:bg-gray-700 dark:divide-gray-600" id="dropdown">
                        <div className="py-3 px-4">
                            <span className="block text-sm font-semibold text-gray-900 dark:text-white">Hello</span>
                            <span className="block text-sm text-gray-500 truncate dark:text-gray-400">{email}</span>
                        </div>
                        <ul className="py-2 text-gray-500 dark:text-gray-400">
                            <li>
                                <Link to='/settings?tab=profile' className="flex items-center py-2 px-4 text-sm  text-gray-600/80 hover:bg-primary-100/80 dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"/>
                                </svg>
                                  Account Settings
                                </Link>
                            </li>
                        </ul>
                        <ul className=" text-gray-600/80" >
                            <li>
                                <div onClick={handlelogout} className="flex cursor-pointer items-center py-2.5 px-4 text-sm hover:bg-primary-100/80 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/></svg>
                                    Sign out
                                </div>
                            </li>
                        </ul>
      </div>)}

  </div>
{islogout &&(
  <Logout actionPopup={handlelogout}/>
)}
</nav>
  )
}
