export default function Header({
  options,
  option,
  isDropdownOpen,
  dropdownRef,
  handleOptionClick,
  handleOption,
  searchquery,
  setSearchQuery,
  handleKeyPress,
  handleSearch,
  handleExport

}) {


  return (
      <div className="flex items-center pt-14 space-x-4">
     {/* Left Dropdown */}
     <div ref={dropdownRef} className="relative">
          <div className="flex font-semibold  -left-1 space-x-3 py-3 px-4 text-gray-800 bg-white rounded-md shadow-default cursor-pointer" onClick={handleOptionClick}>
            <button>{option}</button>
            <svg className="w-[14px] h-[12px] mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
            </svg>
          </div>
          {isDropdownOpen && (
            <div className="z-50 mt-1 -left-1 text-gray-800 absolute rounded-md overflow-hidden bg-white w-48 shadow-default">
              {options.map((option) => (
                <p
                  key={option}
                  className="pl-5 py-2 font-semibold hover:bg-slate-50 hover:text-primary-500 cursor-pointer"
                  onClick={() => handleOption(option)}
                >
                  {option}
                </p>
              ))}
            </div>
          )}
        </div>


      {/* Search Bar*/}
      <div className="flex-1 w-full h-12 rounded-md bg-white overflow-hidden relative outline-none shadow-[0px_0px_0px_1px_#f7fafc]" id='text'>
        <input type="search" 
        onKeyPress={handleKeyPress}   
        value={searchquery}
        onChange={(e)=>setSearchQuery(e.target.value)}
        autoComplete="off"
        className="peer h-full w-full outline-none bg-transparent rounded-md border-primary-500 border text-base text-gray-700 pl-12 pr-2" placeholder="Search domain name..." autoFocus id="default-search" required/>
          <div className="ml-3 absolute top-3 text-gray-400 hover:text-primary-500 focus:text-primary-500" >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 bg-transparent" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
      {/* Search Button */}
        <button onClick={handleSearch} className="px-6 bg-primary-500 rounded-md overflow-hidden border border-primary-500 text-white text-md py-2.5 font-semibold hover:bg-primary-600 shadow-default">
          Search
        </button>
         {/*Export  */}
      <div className="w-28 py-2.5 px-2 rounded-md shadow-default hover:bg-primary-600 flex items-center justify-center space-x-2 bg-primary-500 border border-primary-500 cursor-pointer">
            <svg className="h-5 w-5" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5L11.2929 4.29289L12 3.58579L12.7071 4.29289L12 5ZM13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14L13 14ZM6.29289 9.29289L11.2929 4.29289L12.7071 5.70711L7.70711 10.7071L6.29289 9.29289ZM12.7071 4.29289L17.7071 9.29289L16.2929 10.7071L11.2929 5.70711L12.7071 4.29289ZM13 5L13 14L11 14L11 5L13 5Z" fill="#ffffff"/>
                <path d="M5 16L5 17C5 18.1046 5.89543 19 7 19L17 19C18.1046 19 19 18.1046 19 17V16" stroke="#ffffff" stroke-width="1"/>
            </svg>
        <button onClick={handleExport} className="text-white font-semibold">Export</button>
      </div> 
      </div> 
         
  );
}