import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from '../src/Components/API/axios';
import Login from "./Components/Auth/Login/login";
import Logout from "./Components/Auth/Logout/logout";
import Forgot from './Components/Auth/Forgot/forgot';
import Recoverpassword from './Components/Auth/Forgot/Recoverpassword';
import Home from './Components/Home/home';
import Contact from './Components/Contact/contact';
import Analytics from './Components/Analytics/analystics';
import Register from './Components/Auth/Register/Register';
import Settings from './Components/Settings/settings';
import Pagenotfound from './Components/Common/ServerError/Pagenotfound';
import Verifyemail from './Components/Auth/verifyemail/verifyemail';
import LandingPage from './Components/Landing/landingpage';
import ProtectedRoutes from './Components/Utils/ProtectedRoutes';
import Privacy from './privacy page/Privacy';
import Layout from './layout/Layout';

function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    axios.get("/api/v1/auth/state", { withCredentials: true })
      .then(response => {
        if (response.data.message === 'user is authenticated' && response.status === 200) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      })
      .catch(() => {
        setAuth(false);
      });
  }, []);

  if (auth === null) {
    return null; // or a loading spinner
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><LandingPage /></Layout>} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={auth ? <Navigate to="/home" /> : <Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/register/verify/email" element={<Verifyemail />} />
        <Route path="/recover/password" element={<Recoverpassword />} />
        <Route path="/privacy" element={<Privacy/>}/>
        <Route element={<ProtectedRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/*" element={<Pagenotfound />} />
      </Routes>
    </Router>
  );
}

export default App;
