import { useNavigate } from 'react-router-dom';
import { AppTitle } from '../../Common/Apptitle/Title';
import Img from "../../../Assets/500 Error.svg"

const Demo = () => {

  const navigate = useNavigate(); 

  AppTitle(" Server Down | Protective DNS")

  return (
    <div className="fixed inset-0 z-50 bg-slate-100 flex items-center justify-center">
      <div className="w-full lg:w-1/2 flex flex-col items-center justify-center lg:px-2 xl:px-0 text-center">
        <p className="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider text-gray-300">500</p>
        <p className="text-4xl pb-8 md:text-5xl lg:text-6xl font-bold tracking-wider text-gray-300 mt-2">Internal Server Error</p>
        <button
          className="flex items-center space-x-2 bg-primary-500 hover:bg-primary-600 text-gray-100 px-8 py-2.5 rounded"
          title="Return Home"
          onClick={() => navigate('/home')}
        >
           <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path>
          </svg>
          <span onClick={() => navigate('/home')}>Return Home</span>
        </button>
      </div>
      <div className="w-1/2 lg:h-full flex lg:items-end justify-center p-4">
        <img src={Img} alt=''/>      
      </div>
    </div>
  );
};

export default Demo;
