import React, { useEffect, useState } from 'react'
import axios from '../../API/axios';
import Invalidtoken from '../../Common/ServerError/invalidtoken';
import { Link } from 'react-router-dom';
import ServerError from '../../Common/ServerError/serverdown'
import { AppTitle } from "../../../Components/Common/Apptitle/Title";
import Loading from '../../Common/loading/loading';
import Lottie from 'lottie-react';
import success from "../../../Assets/emailverified.json"


export default function VerifyEmail() {
  const [invalidToken, setInvalidToken] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // App Title
  AppTitle("Verifyemail| ShadowFinder")

  useEffect(()=>{
    const currentUrl = new URL(window.location.href);
    const token = currentUrl.searchParams.get('token');
    setIsLoading(true);
    axios.put("/api/v1/register/verify/email", null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  },{withCredentials: true})
    .then(function(response){
        setIsLoading(false);
    }).catch(function(error){
      if (error.response){
        if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            setInvalidToken(true) 
        } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
          setInvalidToken(true) 
        } else if (error.response.data.error === 'invalid_token' && error.response.data.message === 'signiture verification failed'){
            setInvalidToken(true) 
        } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            setInvalidToken(true) 
        } else{
            setServerError(true);
        }
      } else{
        setServerError(true);
      }
      setIsLoading(false);
    })
  },[])



  return (
    
    <section className="bg-gray-50 bg-no-repeat   bg-cover bg-center flex items-center justify-center  h-screen">
      {invalidToken ? (
        <Invalidtoken />
      ) : (
        <>
          {isLoading && (<Loading />)}
          {serverError ? (
            <ServerError />
          ) : (
        <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-xl lg:max-w-3xl rounded-3xl lg:p-12">
                <h3 className="text-2xl">Email address successfully verified!</h3>
                <div className="md:mx-auto">
                      <Lottie
                          animationData={success}
                          loop={true}
                          autoplay={true}
                          className='w-56 h-56 mx-auto'
                        />
                </div>
                <div className="mt-4">
                    <Link to="/login" className="px-4 py-2 text-white bg-primary-500 rounded">Back to Sign In</Link>
                </div>
            </div>  
            )}
            </>
          )}
     </section> 
  )
}
