// Profile Menu
import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../API/axios';
import Loading from '../../Common/loading/loading';
import ServerError from "../../Common/ServerError/serverdown"
import { AppTitle } from '../../Common/Apptitle/Title';

export default function Profile() {
    const [email, setEmail] = useState('');
    const [creditcount, setCreditcount] = useState('');
    const [creditlimit, setCreditlimit] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const navigate = useNavigate();

  // App Title
  AppTitle("Profile| ShadowFinder")


        // Profile 
        useEffect(()=>{
            setIsLoading(true);
            axios.get("/api/v1/profile",
            {withCredentials: true})
            .then(function(response){
                setEmail(response.data.email);
                setCreditcount(response.data.credit_count);
                setCreditlimit(response.data.credits);
                setIsLoading(false);
            }).catch(function(error){
                if (error.response){
                    if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                      navigate("/")
                    } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                      navigate("/")
                    } else{
                      setServerError(true);
                    }
                  } else{
                    setServerError(true);
                  }
                  setIsLoading(false);
            })
        },[navigate])
    

  return (
    <section className="w-full h-full">
        {isLoading && (<Loading/>)}
        {serverError ?(<ServerError/>
            ):(
            <>
            <div className=' w-full h-full px-4'>
            <p className='text-xl font-semibold text-gray-600 pb-4'>Profile Information</p>
            <hr className='text-gray-700'/>
                    
                <div className='flex px-4 text-gray-600 font-semibold pb-2 pt-6'>
                    {/* <img className="w-24 h-24 rounded-full shadow-md border border-slate-300" src="https://static.vecteezy.com/system/resources/previews/004/607/791/non_2x/man-face-emotive-icon-smiling-male-character-in-blue-shirt-flat-illustration-isolated-on-white-happy-human-psychological-portrait-positive-emotions-user-avatar-for-app-web-design-vector.jpg" alt='profile'/> */}
                        <div class="bg-white ml-6  w-[650px] shadow-default overflow-hidden rounded-md ">
                            {/* <div class="px-4 py-5 sm:px-6">
                                <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Prajwal K
                                </h3>
                            </div> */}
                            <div class="border border-gray-200">
                                <dl> 
                                    {/* Emial  */}
                                    <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-md font-medium text-gray-500">Email</dt>
                                        <dd class="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
                                    </div>
                                    {/* Credit Count */}
                                    <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-md font-medium text-gray-500">Credit Count</dt>
                                        <dd class="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">{creditcount}</dd>
                                    </div>
                                    {/* Credit Limit */}
                                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt class="text-md font-medium text-gray-500">Credit Limit</dt>
                                        <dd class="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">{creditlimit}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
            </div>
            </>
            )}
    </section>
  )
}
