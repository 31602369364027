import React, { useEffect, useState } from 'react'
import axios from "../../API/axios";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Loading from '../../Common/loading/loading';
import ServerError from '../../Common/ServerError/serverdown';
import ErrorToast from '../../Common/Toast/Errortoast';
import SuccessToast from '../../Common/Toast/Successtoast';
import { useNavigate } from 'react-router-dom';


export default function Emailchange() {
  const [email, setEmail] = useState('');
  const [newemail, setNewEmail] = useState('');
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [password, setPassword] = useState('');
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();

  
  // password visibility state
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // password handle
  const handlePassword = () => {
  setPasswordVisibility(!passwordVisibility);
  };

  //update email api
  useEffect(()=> {
      setIsLoading(true);
          axios.get("/api/v1/email/data",
          {withCredentials: true})
          .then(function(response){
              setEmail(response.data.email);
              setIsLoading(false);
          }).catch(function(error){
              setServerError(true);
              setIsLoading(false);
          })
  },[])

  const handleEmailChange = (e) => {
   
      e.preventDefault();
      if (newemail.length === 0) {
          showErrorToast("Please Provide New Email !");
      } else if (!emailRegex.test(newemail)) {
          showErrorToast("Please Enter a valid email !");
      } else if (password.length === 0) {  
          showErrorToast("Please Enter Password !");
      } else {
          setIsLoading(true);
          axios.put("/api/v1/update/email", {
              new_email: newemail,
              password: password 
          },{withCredentials: true}
          ).then(function (response) {
              if (response.data.message === 'email is sent' && response.status === 200){
                  showSuccessToast("Verify your new email by clicking the link sent to your inbox.");
                  setNewEmail('');
                  setPassword('');
              }
              setIsLoading(false);
          }).catch(function (error) {
              if (error.response){
                  if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
                      showErrorToast("Too many request try after 1 hour");
                  } else if (error.response.data.message === 'invalid current password' && error.response.status === 401){
                      showErrorToast("Invalid current Password");
                  } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                      navigate("/")
                  }else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                    navigate("/")
                  } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                    navigate("/")
                  } else{
                    setServerError(true);
                  }
                } else{
                  setServerError(true);
                }
                setIsLoading(false);
          });
      }
  };

  // Toast success
  const showSuccessToast = (message) => {
      setToastMessage(message);
      setIsSuccessToast(true);
  
      setTimeout(() => {
        setIsSuccessToast(false);
      }, 5000);
    };
  
  //   Toast Error
    const showErrorToast = (message) => {
      setToastMessage(message);
      setIsErrorToast(true);
  
      setTimeout(() => {
        setIsErrorToast(false);
      }, 5000);
    };

  return (
    <div className='w-full h-full'>
      {isLoading && (<Loading/>)}
        {serverError ?(<ServerError/>
            ):(
            <>
            <div className=' px-4'>
              <p className='text-xl font-semibold text-gray-600 pb-4'>Update your email address with current.</p>
              <hr className='text-gray-700'/>
                <div className='flex flex-col-2 gap-6 pt-8'>
                  <div className='w-[45%] h-full shadow-default rounded-md px-6 py-4'>
                        <div>
                            <p id="currentemail" className="block mb-2 text-sm font-medium text-gray-900">Current Email</p>
                            <input name="currentemail" type="email" value={email} className="bg-slate-100/80 border border-gray-500 outline-none text-gray-600 placeholder:text-gray-600 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-2 px-2.5" required="" disabled/>
                        </div>
                        {/* New Email */}
                        <div>
                            <p id="newemail" className="block py-2 text-sm font-medium text-gray-900">New Email</p>
                            <input name="newemail" type="email" placeholder="user@company.com" className="bg-transparent border outline-none border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-2 px-2.5" value={newemail} onChange={(e) => setNewEmail(e.target.value)} required="" autoComplete='off'/>
                        </div>
                        {/* Current Password */}
                        <div className='flex flex-col mb-6'>
                          <label htmlFor='password' className='block py-2 text-sm font-medium text-gray-900'>Current Password</label>
                            <div className='relative'>
                              <span className='absolute right-3 top-2 w-5 h-5 cursor-pointer' onClick={handlePassword}>
                                  {passwordVisibility ? <IoEyeOutline /> : <IoEyeOffOutline />}
                              </span>
                          <input id='password' type={passwordVisibility ? 'text' : 'password'} name='password' className='bg-transparent outline-none border border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-1.5 p-2.5' placeholder='current password'required='' value={password} onChange={(e) => setPassword(e.target.value)}autoComplete='off'/>
                              </div>  
                      </div>
                  </div>
                  {/* Email guide */}
                  <div className='w-full h-full shadow-default rounded-md px-6 py-4'>
                    <div className='w-full h-full bg-primary-100 border border-dashed border-primary-500 rounded-md p-5'>
                      <p className='text-md pb-4'>
                        To commence the process of email modification, kindly input your preferred new email address below.<br/> A verification email will be dispatched to this address to validate its authenticity before effecting the change.
                      </p>
                      <p className='text-md'>
                        Please kindly examine your email inbox for the verification email and follow the instructions provided there in to conclude the process.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Email change Button */}
                  <div className='flex justify-end'>
                    <button className="text-white font-semibold bg-sky-600 hover:bg-sky-700 focus:ring-4 focus:outline-none focus:ring-sky-300 rounded-md px-6 py-2.5 text-center" onClick={handleEmailChange}>Change Email</button>
                  </div>
            </div>
                {/* Toast */}
                {isErrorToast && (
                    <ErrorToast toastMessage={toastMessage}/>
                  )}
                  {isSuccessToast && (
                    <SuccessToast toastMessage={toastMessage}/>
                  )}
            </>
            )}


    </div>
  )
}
