import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Invalidtoken() {
  const navigate = useNavigate();

  return (
    <section className="flex items-center justify-center h-full">
        <div className="max-w-xl p-8 text-gray-800 bg-white shadow-xl lg:max-w-3xl">
                <h3 className="text-3xl font-extrabold">The verification token is invalid!</h3>
                <p className='mt-2'>Something went wrong on your way to this page</p>
                <p className='mt-4'>The page you've arrived at has an invalid verification token in the URL. Make sure you clicked on the link emailed to you as part of the verification process and that you followed the link within 15 minutes of requesting the verification email.</p>
                <div className="mt-4 space-y-4">
                   <p>Click below button to back to dashboard</p>
                    <button onClick={()=>navigate("/dashboard")} className="px-4 py-2 text-white bg-primary-500 rounded">Back to dashboard</button>
                </div>
            </div>     
     </section>
  )
}
