import React from 'react'
// import Navbar from '../Components/Navbar/navbar'
import Footer from '../footer/Footer'




export default function Layout({children}) {
    return (
      <div className='w-screen h-screen overflow-hidden flex flex-col'>
         
          <div className='w-full h-full overflow-hidden flex'>
              <div className='w-full h-full flex-grow overflow-y-auto overflow-x-hidden'>
                  {children}
              </div>
          </div>
          <Footer/>
      </div>
    )
  }
  