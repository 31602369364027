import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from '../API/axios';

export default function ProtectedRoutes() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    axios.get("/api/v1/auth/state", { withCredentials: true })
      .then(function (response) {
        if (response.data.message === 'user is authenticated' && response.status === 200) {
          setAuth(response.data.result);
        } else {
          setAuth(false); // Redirect to login for any other cases like unauthorized access
        }
      })
      .catch(function (error) {
        console.error("Error fetching authentication state:", error);
        setAuth(false); // Redirect to login on error
      });
  }, []);

  if (auth === null) {
    return null; // or loading indicator
  }

  return (
    auth ? <Outlet /> : <Navigate to='/login' />
  );
}
