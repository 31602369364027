import React from 'react'
import icon from '../../Assets/mainiconb.ico';
import {Link} from 'react-router-dom';

export default function Search({domain, setDomain, handleSearch}) {
  return (
    <section className='w-full h-full p-10 bg-white'>
        <div className='flex flex-col space-y-12 items-center justify-center h-full'>
            <div className='flex items-center justify-center gap-8'>
                <img className="w-36 h-36 rounded-full" src={icon} alt=''/>
            </div>
            <p className='text-gray-600 text-center font-semibold text-4xl font-inter'>
            Learn about <Link to='https://shreshtait.com/blog/2024/02/domain-shadowing/' className='underline font-inter' target="_blank" rel="noopener noreferrer">domain shadowing attacks</Link>
            </p>

            <div className="pt-2 relative mx-auto text-gray-600 w-[32%] group">
        <input className="bg-white py-2.5 px-4 w-full pl-12 rounded-full outline-none border-2 border-primary-400"
          type="text"
          placeholder="Crawl domain name"
          autoFocus
          value={domain}
          onChange={(e)=>setDomain(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearch();
            }
          }}
          id='text'autoComplete='off'/>
          <svg className="h-5 w-5 fill-current absolute left-0 top-0 mt-[22px] ml-4 text-primary-500" xmlns="http://www.w3.org/2000/svg"
            version="1.1" id="Capa_1" x="0px" y="0px" stroke='1.5'
            viewBox="0 0 56.966 56.966"
            width="512px" height="512px">
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
            </div>
            <button className='px-20 py-3 font-semibold bg-primary-500 text-white rounded-full font-inter' onClick={handleSearch}>Crawl</button>
        </div>
    </section>
  )
}
