import ReactApexChart from 'react-apexcharts';

export default function Barchart({ countryData,BarchartHeight }) {
  // Extract counts from countryData
  const counts = countryData.map(data => data.count);

  const series = [{
    name: 'Counts',
    data: counts  // Use extracted counts here
  }];

  const options = {
    chart: {
      id: 'bar-datetime',
      type: 'bar',
      height: 300,
      zoom: {
        autoScaleYaxis: true
      },
      animations: {
        enabled: true // disable animations
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      categories: countryData.map(data => data.country), // Set x-axis categories to country names
      tickAmount: 6,
    },
    fill: {
      colors: ['#007bff'],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };
  
  return (
    <div className="w-full h-full bg-white rounded-md shadow-default" style={{ height: BarchartHeight + 'px' }}>
        <h5 className="text-lg font-bold text-gray-900 py-2.5 pl-5">Country Count</h5>
        <hr className="border-slate-300" />
        {countryData && countryData.length > 0 ? (
            <ReactApexChart options={options} series={series} type="bar" height={300}/>
        ) : (
          <p className="text-gray-800 text-center py-28 font-semibold">No data available</p>
        )}
    </div>
  );
}
