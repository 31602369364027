import React from 'react'
import CountryFlag from 'react-country-flag';
import Toastwarning from '../Common/Toast/Toastwarning';

export default function Table({
    currentPage,
    tableData,
    hasTableData,
    jumpToPage,
    setJumpToPage,
    handlePageChange,
    totalPages,
    handleJumpToPage,
    Toast,
    Toastmessage,
    handletoast  
}) 

{
  return (
    <div className="w-full pb-4 bg-white rounded-md shadow-default">
    {hasTableData ? (
      <>
        <div className="overflow-x-auto sm:overflow-hidden">
          <table className="w-full text-sm sm:text-base text-center border-b border-gray-300 rtl:text-center text-gray-900 bg-white">
            <thead className="text-lg text-slate-50 capitalize bg-primary-500  font-semibold">
              <tr>
                {/* Table Header */}
                <th scope="col" className="px-1  py-3">Timestamp</th>
                <th scope="col" className="px-1  py-3">Domain Names</th>
                <th scope="col" className="px-1  py-3">IP Address</th>
                <th scope="col" className="px-1  py-3">ASN</th>
                <th scope="col" className="px-1  py-3">ASN NAME</th>
                <th scope="col" className="px-1  py-3">Country code</th>
              </tr>
            </thead>
            <tbody>
              {/* Table Body */}
            {tableData.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}> 
              <td className="p-4">{item.timestamp}</td>
                <td className="p-4">{item.subdomain}</td>
                <td className="p-4">{item.ip}</td>
                <td className="p-4">{item.asn}</td>
                <td className="p-4">{item.asnname}</td>
                <td className="p-4 text-[16px] text-gray-900 flex justify-center items-center font-semibold whitespace-nowrap">
                {item.country_code ? (
                   <>
                    <CountryFlag countryCode={item.country_code} svg={true} />&nbsp;&nbsp;
                    {item.country_code}
                  </>
                ) : null}
                </td>
                {/* <td className="p-4">{item.country_code}</td> */}
              </tr>
            ))}
          </tbody>
          </table>
        </div>
        <div className="pagination-container mt-4 m-2 flex flex-col sm:flex-row justify-between items-center">
        <div className="pagination-buttons flex items-center">
          {/* Left Pagination button */}
            <button
              onClick={() =>  handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={` px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
              }`}>
              <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
              </svg>
            </button>
            <span className="mx-2 text-gray-800">{`Page ${currentPage} of ${totalPages}`}</span>
            {/* Right Pagination Button */}
            <button
              className={`px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                currentPage === totalPages
                  ? "text-white cursor-not-allowed"
                  : "text-white cursor-pointer "
              }`}
              onClick={() =>  handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}>
              <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
              </svg>
            </button>
          </div>
          {/* Page Jump */}
          <div className="jump-to-page flex items-center">
              <span className="text-gray-700 mr-2" id='number'>Jump to Page:</span>
               <input id='number' type="number" className="py-1 px-1 w-[60px] border border-slate-400 rounded-md" value={jumpToPage} onChange={(e) => setJumpToPage(Math.max(0, parseInt(e.target.value)))}/>
              <button className="px-2 py-1 ml-2 bg-primary-500 text-white rounded-md hover:bg-primary-600" onClick={handleJumpToPage}>
                Go
              </button>
          </div>
        </div>
      </>
    ) : (
      <p className="text-gray-800 text-center py-4 ">No data available</p>
    )}
      {Toast && <Toastwarning className="ml-auto" message={Toastmessage} handletoast={handletoast} />}
  </div>
  )
}
