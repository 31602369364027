import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppTitle } from '../Common/Apptitle/Title';
import Profile from './Profile/profile';
import ChangeEmail from './Email change/emailchange';
import ChangePassword from './Password change/passwordchange';
import Twofactor from './Twofactor/twofactor';
import Navbar from "../Navbar/navbar"

export default function Settings() {
    // Title
    AppTitle("Settings | ShadownFinder")
  
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('profile');
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const tab = params.get('tab');
      if (tab) {
        setActiveTab(tab);
      }
    }, [location.search]);
  
    const handleTabClick = (tab) => {
      setActiveTab(tab);
      navigate(`/settings?tab=${tab}`);
    };

    const renderTabContent = () => {
      switch(activeTab) {
        case 'profile':
          return <Profile />;
        case 'changeemail':
          return <ChangeEmail />;
        case 'changepassword':
          return <ChangePassword />;
        case 'twofactor':
          return <Twofactor />;
        default:
          return null;
      }
    };
  
    return (
    <div className='bg-slate-100 w-screen h-screen overflow-x-auto'>
            <Navbar/>
            <div className='w-full h-[720px] py-4 px-1'>
            <section className="w-full h-full bg-white justify-center items-center mt-12 rounded-md shadow-default">
                <h1 className="border-b  border-gray-400/40 p-3 pl-5 text-3xl font-semibold">Settings</h1>
                <div className="flex">
                  <div className="border-r h-[627px] hidden sm:block w-[15%] px-1 pb-4">
                    <ul>
                      <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'profile' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('profile')}>Profile</li>
                      {/* <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'changeemail' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('changeemail')}>Email</li> */}
                      <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'changepassword' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('changepassword')}>Password</li>
                      {/* <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'twofactor' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('twofactor')}>Security</li> */}
                    </ul>
                  </div>
                  <div className="flex-1">
                    <hr className="mt-4 border-gray-300 w-full mb-4" />
                    {renderTabContent()}
                  </div>
                </div>
              </section>
            </div>
      </div>
    );
}
