import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-800 fixed bottom-0 left-0 w-full  z-50 py-2">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center text-xs text-gray-300">
          <Link to="https://shreshtait.com/" target="_blank" rel="noopener noreferrer">© 2024 Shreshta IT Technologies Pvt. Ltd. All rights reserved. </Link>
          <Link to="/privacy" target="_blank" rel="noopener noreferrer" className='underline'>Privacy Policy</Link>
          </div>
        </div>
      </footer>
  )
}
